.gmConfirmation {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  background-color: rgba(71, 77, 85, 1);
  border-radius: 8px;
  position: absolute;
  z-index: 9999;
  height: 250px;
  width: 500px;
  margin: calc(-20vh + 250px) auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.gmConfirmation h4 {
  display: inline-block;
  text-align: center;
}

.gmConfirmation button {
  margin: 25px auto;
  width: 50%;
  border: 1px solid #E29006;
}
